import React from "react"
import { FooterWithCTA as Footer, SEO, Layout, Hero } from "@components"
import Content from "@components/pages/faq/Content.js"
import SideCTA from "@components/SideCTA"
import faqs from "@constants/faqs.js"
const faqCount = faqs.length;

const FAQPage = () => {
  return (
    <>
      <Layout>
        <SEO
          title={`DynamoDB FAQ - Top ${faqCount} DynamoDB Questions Answered`}
          description={`Top DynamoDB FAQ is a compilation of frequently asked questions about Amazon DynamoDB along with an answer for each of them.`}
          canonical="https://dynobase.dev/dynamodb-faq/"
        />
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Hero title={`DynamoDB FAQ - Top ${faqCount} DynamoDB Questions Answered`} />
          <Content />
        </div>
        <Footer />
      </Layout>
      <SideCTA />
    </>
  )
}

export default FAQPage
