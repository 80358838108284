import React from "react"
import { Container } from "@components"
import { Box, Flex } from "rebass"
import faqs from "@constants/faqs.js"
import { InternalLink } from "@fragments"

const Content = () => (
  <Container>
    <Box mt={[32, 62]}>
      <Flex flexDirection="column">
        <p>
          Top DynamoDB FAQ is a compilation of frequently asked questions about Amazon DynamoDB along with an answer for each of them.
        </p>
        <ul>
          {faqs
            .map((faq, index) => (
              <li key={`faq-${index + 1}`} style={{ marginBottom: 0 }} className="list-item-with-spacing">
                <InternalLink to={`/dynamodb-faq/${faq.slug}/`}>
                  {faq.question}
                </InternalLink>
              </li>
            ))}
        </ul>
      </Flex>
    </Box>
  </Container>
)

export default Content
